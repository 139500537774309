import React from 'react'

import { DateRangeSelect, Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { DateRangeSelectExample } from '../../examples/react/DateRangeSelectExample'
import { Section } from '../../components/Section'
import { Code } from '../../components/Code'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="DateRangeSelect"
    components={[{ component: DateRangeSelect }]}
    status={[{ type: 'accessible', version: '15.3.0' }]}
  >
    <Section>
      <Playground
        enableOverflow
        example={DateRangeSelectExample}
      />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>
        Komponenttia ei suositella pakollisten aikajaksojen merkkaamiseen. Käytä{' '}
        <Code>DateInput</Code>-komponenttia suoraan lomakkeella. Komponentti
        avautuu modaliin, eikä varsinaisella lomakkeella ole input-kenttää, joka
        voitaisiin merkitä pakolliseksi ja jonka ruudunlukija näin voisi lukea.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
